import './Header.css';

const Header = () => {
   return(
    <div className="appHeader">
        <span className='mainHeader'>
            <ul>
                <li>
                    Home
                </li>
                <li>
                   Contact 
                </li>
                <li>
                    About Us
                </li>
                <li>
                    
                </li>
            </ul>
            <a href='#' className='searchButton'> Search <i class="fa-solid fa-magnifying-glass"></i></a>
        </span>
       <p className='portfolio'>
           <span>PORTFOLIO</span>
           <span> > 2024</span>
        </p>
        
     </div>
   );
}

export default Header;