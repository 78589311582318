import {useState} from 'react';
import './Binance.css';
const Binance = () => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="binanceTopic">

            <br></br>
            <span>
                <p
                    className="binanceName"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    binance
                </p>
            </span>
            {
                isHovered && <video
                        className='videoBackground'
                        autoPlay
                        muted
                        loop>
                        <source
                            src="https://assets-global.website-files.com/65ec8403fa620f5552b86fdc/65ede225474caed9e5d9ed35_pexels-google-deepmind-18069234%20(1080p)-transcode.mp4"
                            type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
            }
        </div>
    )
};

export default Binance;