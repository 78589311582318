import './App.css';
import Header from './Header/Header';
import Apple from './Topics/Apple/Apple';
import Google from './Topics/Google/Google';
import Binance from './Topics/Binance/Binance';
import Fiverr from './Topics/Fiverr/Fiverr';
import ViewAll from './ViewAll/ViewAll';
import Footer from './Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Google />
      <Apple />
      <Binance />
      <Fiverr />
      <ViewAll />
      <Footer />
    </div>
  );
}

export default App;
