import './Footer.css';

const Footer = () => {
    return(
        <div className='footerSection'>
            <div className='topFooterSection'>
                <p className='myNameTitle'>
                    Reza Rezaeian
                </p>
                <p className='options'>
                    <span><a href='https://rezarezaeian.com' target='_blank'>Go to my Site</a> <a href='https://rezarezaeian.com' target='_blank'>Contact Me</a></span>
                </p>
                <p className='socialMedia'>
                    <a href='https://instagram.com/reza_1' target='_blank'><i class="fa-brands fa-instagram"></i></a>
                </p>
            </div>
            <div className='bottomFooterSection'>
                <div className='bottomFooterLeftSection'>
                    <p>© 2024</p>
                </div>
                <div className='bottomFooterRightSection'>
                    <p>Made by Rezzz</p>
                </div>
            </div>
        </div>       
    )
}

export default Footer;