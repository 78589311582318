import './ViewAll.css';

const ViewAll = () =>{
    return(
        <div className='viewAllSection'>
            <div className='colorViewAllSection'>
                <div className='colorViewAllLeft'>
                    <br></br>
                <p className='viewAllTitle'>
                     Loved this template? 
                </p>
                <div className='viewAllExplain' id='footerDirect'>
                    <p>
                        Call me now to create your own website.
                    </p>
                    <p className='callMeLink'>
                        <a href='https://rezarezaeian.com'>Call Me</a>
                    </p>
                </div>

                </div>
                <div className='colorViewAllRight'></div>
            </div>
        </div>
    )
}

export default ViewAll;