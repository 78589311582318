import {useState} from 'react';
import './Fiverr.css';
const Fiverr = () => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="fiverrTopic">
            <br></br>
            <span>
                <p
                    className="fiverrName"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    Fiverr
                </p>
            </span>
            {isHovered && <video className='videoBackground' autoPlay muted loop>
                        <source
                            src="https://assets-global.website-files.com/65ec8403fa620f5552b86fdc/65eddf668090071627fd0d2c_pexels-google-deepmind-18069862%20(1080p)-transcode.mp4"
                            type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video> }
            <p className='viewAllButton'>
                View All
            </p>
            <p className='arrowDownSection'>
                <a href='#footerDirect' className='arrowDown'>
                    <i className='fa-solid fa-arrow-down'></i>
                </a>
            </p>
        </div>
    )
};

export default Fiverr;